@mixin resp($type) {
  @if ($type == 'lg') {
    @media only screen and (min-width: 1170px) {
      @content;
    }
  } @elseif ($type == 'md') {
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @elseif ($type == 'sm') {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @elseif ($type == 'lxs') {
    @media only screen and (min-width: 562px) {
      @content;
    }
  } @elseif ($type == 'xs') {
    @media only screen and (min-width: 120px) {
      @content;
    }
  }
}

.slide-container {
  position: relative;
  width: 100%;
  min-height: 120px;
  background-color: #f8f8f8;

  .intro-container {
    margin: 0 auto;
    padding-top: 3em;
    font-size: 16px;
    @include resp('xs') {
      width: 100%;
    }
    @include resp('sm') {
      width: 720px;
    }
    @include resp('md') {
      width: 960px;
    }
    @include resp('lg') {
      width: 1000px;
    }
  }

  .shelf-container {
    position: relative;
    background-image: url('imgs/shelf.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    margin: 0 auto;

    @include resp('xs') {
      width: 80%;
      padding-bottom: 42%;

      .image-box {
        bottom: 11.9%;
      }
    }
    @include resp('lxs') {
      width: 88%;
      padding-bottom: 42%;

      .image-box {
        bottom: 17%;
      }
    }
    @include resp('sm') {
      width: 720px;
      height: 300px;
      padding-bottom: initial;

      .image-box {
        bottom: 20%;
      }
    }
    @include resp('md') {
      width: 960px;
      height: 400px;

      .image-box {
        bottom: 20%;
      }
    }
    @include resp('lg') {
      width: 1000px;
      height: 480px;
      margin-bottom: 60px;
      .image-box {
        width: 16%;
        bottom: 18%;
      }
    }

    .image-box {
      width: 16%;
    }
  }

  .show-title {
    // top: 70%;
    position: relative;
    color: #fff;
    text-align: center;
    font-size: 24px;
    min-height: 1.5em;
  }

  .text-container {
    position: relative;
    top: 0;
    color: #fff;
    background-color: #C6BCB2;
    width: 100%;
    min-height: 180px;
    margin-top: 18px;
    padding-top: 4px;
  }

  .show-description {
    font-size: 15px;
    line-height: 30px;
    position: relative;
    // top: calc(70% + 3em);
    width: 80%;
    left: 10%;
    text-align: left;
    padding-top: 18px !important;
    padding-bottom: 2em;
  }

  .link {
    color: #fff !important;
  }
}

.image-box {
  position: absolute;
  transform: scale(1);
  transition: transform 400ms, left 400ms;

  .image {
    max-width: 100%;

    img {
      width: 100%;
    }
  }

  .tooltip {
    position: absolute;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 2px 4px;
    bottom: 16px;
    left: 16px;
    width:100%;
  }

  &[data-position="0"] { left: 12%; }
  &[data-position="1"] { left: 33%; }
  &[data-position="2"] { left: 54%; }
  &[data-position="3"] { left: 74%; }

  &[data-position="0"] {
    &.-adding {
      left: 0;
      transform: scale(0);
    }

    &.-removing {
      left: 0%;
      transform: scale(0);
    }
  }

  &[data-position="3"] {
    &.-adding {
      left: 83%;
      transform: scale(0);
    }

    &.-removing {
      left: 83%;
      transform: scale(0);
    }
  }
}

.btn {
  z-index: 10;
  position: absolute;
  cursor: pointer;

  @include resp('xs') {
    top: 55%;
    width: 18px;
    height: 18px;
    &.btn-left {
      left: -12%;
    }
    &.btn-right {
      right: -12%;
    }
  };

  @include resp('lxs') {
    &.btn-left {
      left: -20px;
    }
    &.btn-right {
      right: -20px;
    }
  }

  @include resp('sm') {
    top: 45%;
    width: 45px;
    height: 45px;
    &.btn-left {
      left: -12px;
    }
    &.btn-right {
      right: -12px;
    }
  }

  @include resp('md') {
    top: 45%;
  }

  @include resp('lg') {
    top: 52%;
  }

  &.btn-left {
    background-image: url('imgs/back.svg');
  }
  &.btn-right {
    background-image: url('imgs/next.svg');
  }
}
.hs-title {
  display: inline-block;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='54' height='54'><rect width='16' height='16' fill='#FFF' /></svg>");
  background-size: 12px 12px;
  background-repeat: repeat-x;
  background-position: 4px 42px;
  &::before {
    content: '｜';
  }
  &::after {
    content: '｜';
  }
}
.show-title-container {
  margin: 0 auto;
}
